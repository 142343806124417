import {Link, withPrefix} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Layout} from '../../../components/layout';

export default () => {
    return (
        <Layout
            title="Algemene voorwaarden voor opdrachtgevers"
            description="Softwareproductontwikkeling is een omvangrijk, risicovol en ingewikkeld proces. Daarbij hoort een goed doordacht verhaal met glasheldere voorwaarden."
            ogImage="voorwaarden.png"
            translatedPagePath="/en/terms-and-conditions/">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Onze <br /> Voorwaarden
                        </h2>
                        <div className="display__number">V</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Software&shy;product&shy;ontwikkeling is een om&shy;vangrijk, risicovol en ingewikkeld{' '}
                            proces. Daarbij hoort een goed door&shy;dacht verhaal met glas&shy;heldere voorwaarden.
                        </p>
                        <p>
                            Hieronder vind je onze <span className="highlight">algemene voor&shy;waarden</span>, welke{' '}
                            van toepassing zijn op al onze over&shy;eenkomsten. In de voor&shy;waarden zijn{' '}
                            ver&shy;schillende be&shy;palingen gebaseerd op onder&shy;delen van de{' '}
                            <a
                                href="https://www.nederlandict.nl/diensten/nederland-ict-voorwaarden/"
                                target="_blank"
                                rel="external nofollow"
                                title="Website van branchevereniging Nederland ICT">
                                Nederland&nbsp;ICT branch&shy;voorwaarden
                            </a>
                            . Daar&shy;naast zijn er be&shy;palingen die zijn toe&shy;gespits op onze unieke aanpak.{' '}
                        </p>
                        <p>
                            We geven onze opdracht&shy;gevers een eerlijk en duidelijk verhaal&mdash;
                            <span className="highlight">vooraf</span>. Dit doen we met een uit&shy;gebreide{' '}
                            begrijp&shy;bare{' '}
                            <Link
                                to="/nl/voorwaarden/uitgebreide-toelichting/"
                                title="Toelichting op onze dienstverlening en werkwijze">
                                toe&shy;lichting
                            </Link>{' '}
                            op onze precieze voor&shy;waarden en werk&shy;wijze. Hierin scheppen we{' '}
                            <span className="highlight">duidelijk&shy;heid</span> over alle aspecten van het proces{' '}
                            voordat het aanvangt.
                        </p>
                    </div>

                    <div className="content__buttons content__buttons--50">
                        <Button
                            direct
                            url={withPrefix('/files/algemene-voorwaarden.pdf')}
                            name="Algemene voorwaarden (PDF)"
                            className="button--download"
                            title="Download onze Algemene Voorwaarden als PDF"
                        />
                        <Button
                            url="/nl/voorwaarden/uitgebreide-toelichting/"
                            name="Naar de uitgebreide toelichting"
                            title="Uitgebreide toelichting dienstverlening en werkwijze"
                        />
                    </div>
                </div>
            </article>
        </Layout>
    );
};
